import React, { useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

/* Components */
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  TextField,
  useMediaQuery,
  useTheme,
  MenuItem,
} from "@material-ui/core";

/* Style */
import { useStyles } from "./style";
import clsx from "clsx";

/* Services */
import VehicleService from "../../../services/VehicleService";

/* Hooks */
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import useCurrentFacility from "../../../hooks/useCurrentFacility";

/* Form State */
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* Utilities */
import { isUndefined } from "lodash";
import apiClient from "../../../auth/apiClient";

import { VEHICLE_TYPE } from "../../../constants/index";

const vehicleService = new VehicleService(apiClient);

Yup.addMethod(Yup.object, "atLeastOneOf", function(list) {
  return this.test({
    name: "atLeastOneOf",
    message: "${path} must have at least one of these keys: ${keys}",
    exclusive: true,
    params: { keys: list.join(", ") },
    test: (value) => value == null || list.some((f) => value[f] != null),
  });
});

export const VehicleSchema = Yup.object()
  .shape({
    make: Yup.string()
      .trim("No leading or trailing spaces...")
      .nullable()
      .max(30, "Maximum of 30 characters"),
    model: Yup.string()
      .trim("No leading or trailing spaces...")
      .nullable()
      .max(30, "Maximum of 30 characters"),
    vehicleYear: Yup.string()
      .trim("No leading or trailing spaces...")
      .nullable()
      .max(30, "Maximum of 30 characters"),
    color: Yup.string()
      .trim("No leading or trailing spaces...")
      .nullable()
      .max(30, "Maximum of 30 characters"),
    licensePlate: Yup.string()
      .trim("No leading or trailing spaces...")
      .nullable()
      .max(30, "Maximum of 30 characters"),
    useAsCredential: Yup.bool(),
    vehicleType: Yup.number()
    .nullable(),
  })
  .atLeastOneOf(["make", "model", "vehicleYear", "color", "licensePlate","vehicleType"]);

const VehicleForm = ({ data, onSubmit, onDelete, disabled }) => {
  const classes = useStyles();
  const isEditVehicle = !isUndefined(data.vehicleID);
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { showDialog } = useConfirmationDialog();
  const lprEnabled = useFeatureFlag('LPR');
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const vechileTypes = [
    {
      name: "Gasoline",
      value: VEHICLE_TYPE.Gasoline,
    },
    {
      name: "Electric",
      value: VEHICLE_TYPE.Electric,
    },
    {
      name: "Hybrid",
      value: VEHICLE_TYPE.Hybrid,
    } 
  ];

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid, isValidating, isDirty, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(VehicleSchema),
    defaultValues: {
      ...data,
    },
  });

  useEffect(() => {
    validateEnableDisableSaveAndCancelBtn();
  }, [isValid, isValidating, isDirty]);

  const validateEnableDisableSaveAndCancelBtn = () => {
    const { make, model, vehicleYear, color, licensePlate, vehicleType } = getValues();
    if (make?.trim().length > 0
      || model?.trim().length > 0
      || vehicleYear?.trim().length > 0
      || color?.trim().length > 0
      || licensePlate?.trim().length > 0
      || vehicleType) {
      setDisableSaveButton(false);
    } else {
      setDisableSaveButton(true);
    }
  };
  
  const deleteVehicle = async () => {
    const response = await showDialog({
      title: "Delete Vehicle",
      message: `Are you sure you want to remove this vehicle from this access holder?`,
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" },
      ],
    });
    if (response === "No") return;

    try {
      await vehicleService.RemoveVehicleFromAccessHolder(
        data.accessHolderID,
        data.vehicleID,
        data.credentialID || ""
      );
    } catch {
      enqueueSnackbar("Failed to remove vehicle from access holder", {
        variant: "error",
        tag: "removeVehicleError",
      });
      return;
    }

    enqueueSnackbar("Successfully removed vehicle", {
      variant: "success",
    });

    onDelete(data.vehicleID);
  };

  const createOrUpdateVehicle = async (values) => {
    let {
      vehicleID,
      accessHolderID,
      licensePlate,
      make,
      model,
      vehicleYear,
      color,
      useAsCredential,
      vehicleType,
    } = values;
    if (!accessHolderID) {
      enqueueSnackbar("No access holder associated with this vehicle", {
        variant: "error",
        tag: "noAccessHolderError",
      });
      return;
    }

    if (!licensePlate) {
      useAsCredential = false;
    }

    const vehicle = {
      accessHolderID,
      licensePlate,
      make,
      model,
      vehicleYear,
      color,
      facilityID : scopeAwareFacilityID,
      contractID: data.contractID,
      credentialID: data.credentialID,
      useAsCredential,
      vehicleType,
    };

    if (!isEditVehicle) {
      try {
        await vehicleService.CreateVehicleForAccessHolder(
          accessHolderID,
          vehicle
        );
        enqueueSnackbar("Successfully created vehicle", {
          variant: "success",
        });
      } catch (err) {
        if (err.response.status === 400) {
          enqueueSnackbar(err.response.data, {
            variant: "error",
            tag: "http400CreateVehicle",
          });
        } else {
          enqueueSnackbar("Failed to create vehicle", {
            variant: "error",
            tag: "createVehicleError",
          });
        }
        return;
      }
    } else {
      try {
        vehicle.vehicleID = vehicleID;
        await vehicleService.UpdateVehicleForAccessHolder(
          accessHolderID,
          vehicle
        );
        enqueueSnackbar("Successfully updated vehicle", {
          variant: "success",
        });
      } catch (err) {
        if (err.response.status === 400) {
          reset();
          enqueueSnackbar(err.response.data, {
            variant: "error",
            tag: "http400VehicleUpdate",
          });
        } else
          enqueueSnackbar("Failed to update vehicle", {
            variant: "error",
            tag: "updateVehicleError",
          });
        return;
      }
    }

    onSubmit();
  };

  const isCheckboxDisabled = () => {
    if (disabled) {
      return true;
    } else {
      const { licensePlate } = getValues();

      if (licensePlate) {
        return false;
      }

      return true;
    }
  };

  const cancelSelections = () => {
    const { accessHolderID, contractID } = getValues();
    reset({
      make: "",
      model: "",
      vehicleYear: "",
      color: "",
      licensePlate: "",
      useAsCredential: false,
      vehicleType: null,
      accessHolderID: accessHolderID,
      contractID: contractID
    });
  };
  
  return (
    <div className={clsx(classes.inlineRoot)}>
      
      <Controller
        name="make"
        control={control}
        render={({ field }) => (
          <TextField
            className={clsx("vehicle-make", classes.flexInput)}
            {...field}
            label="Make"
            inputProps={{
              "aria-label": "Make",
            }}
            disabled={disabled}
            error={errors?.make?.message}
            helperText={errors?.make?.message}
          />
        )}
      />
      <Controller
        name="model"
        control={control}
        render={({ field }) => (
          <TextField
            className={clsx("vehicle-model", classes.flexInput)}
            {...field}
            label="Model"
            inputProps={{
              "aria-label": "Model",
            }}
            disabled={disabled}
            error={errors?.model?.message}
            helperText={errors?.model?.message}
          />
        )}
      />
      <Controller
        name="vehicleYear"
        control={control}
        render={({ field }) => (
          <TextField
            className={clsx("vehicle-year", classes.flexInput)}
            {...field}
            label="Year"
            inputProps={{
              "aria-label": "Year",
            }}
            disabled={disabled}
            error={errors?.vehicleYear?.message}
            helperText={errors?.vehicleYear?.message}
          />
        )}
      />
      <Controller
        name="color"
        control={control}
        render={({ field }) => (
          <TextField
            className={clsx("vehicle-color", classes.flexInput)}
            {...field}
            name="color"
            label="Color"
            inputProps={{
              "aria-label": "Color",
            }}
            disabled={disabled}
            error={errors?.color?.message}
            helperText={errors?.color?.message}
          />
        )}
      />
      <Controller
        name="licensePlate"
        control={control}
        render={({ field }) => (
          <TextField
            className={clsx("vehicle-license-plate", classes.flexInput)}
            {...field}
            label="License Plate"
            inputProps={{
              "aria-label": "License Plate",
            }}
            disabled={disabled}
            error={errors?.licensePlate?.message}
            helperText={errors?.licensePlate?.message}
          />
        )}
      />      
      <Controller
        name="vehicleType"
        control={control}
        render={({ field }) => (
          <FormControl            
            formlabel="vehicle Type"
            variant="outlined"
            className={clsx("vechiletype-form")}
          >
            <InputLabel
              id="vehicleTypeLabel"
              className={clsx("vechiletype-label")}
            >
              Type
            </InputLabel>
            <Select
              {...field}
              displayEmpty
              className={clsx("vechiletype-dropdown")}
              variant="standard"              
              labelId="vehicleTypeLabel"
              label="vehicle Type"
              aria-label="vehicle Type"
              inputProps={{
                id: "vechiletype-dropdown",
              }}             
              value= {field.value ? field.value : ""}
              disabled={disabled}
            >
              {vechileTypes.map((vechileType) => (

                <MenuItem key={vechileType.value} value={vechileType.value} className={clsx(`${vechileType.name}-menu-item`)}
                  name="vechileType" >
                  {vechileType.name}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
        )}
      />
        <Controller
          name="useAsCredential"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              role="use-as-credential-toggle"
              label={
                <label
                  className={clsx(
                    "use-as-credential-label",
                    classes.checkboxLabel
                  )}
                >
                  Use As Credential
                </label>
              }
              labelPlacement={small ? "right" : "top"}
              control={
                <Checkbox
                  className="credential-checkbox"
                  {...field}
                  id="useAsCredential"
                  color="primary"
                  checked={field.value}
                  data-checked={field.value}
                  disabled={isCheckboxDisabled()}
                />
              }
            />
          )}
        />
      {!disabled && (
        < Button
          className={clsx("saveBtn")}
          variant="contained"
          color="primary"
          disabled={disableSaveButton}
          onClick={handleSubmit(createOrUpdateVehicle)}
        >
          Save
        </Button>
      )}
      {!disabled && data.vehicleID && (
        <Button
          className={clsx("deleteBtn")}
          color="secondary"
          variant="contained"
          onClick={deleteVehicle}
        >
          Delete
        </Button>
      )}
      {!data.vehicleID && !disabled && (
        <Button
          data-testid="VehicleCancelButton"
          className={clsx("cancelBtn")}
          color="secondary"
          variant="contained"
          disabled={disableSaveButton}
          onClick={cancelSelections}
        >
          Cancel
        </Button>
      )}
    </div>
  );
};

VehicleForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  onDelete: () => {},
  data: {
    make: "",
    model: "",
    vehicleYear: "",
    color: "",
    licensePlate: "",
    accessHolderID: "",
    useAsCredential: false,
    vehicleType: null
  },
  inline: false,
  disabled: false,
};

VehicleForm.propTypes = {
  data: PropTypes.shape({
    vehicleID: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  inline: PropTypes.bool,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  assignedAccessHolderName: PropTypes.shape({
    first: PropTypes.string.isRequired,
    last: PropTypes.string,
  }),
};

export default VehicleForm;
