import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  flexChild: { margin: theme.spacing(1), flexGrow: "1" },
  "validation-user-panel": {
    display: "flex",
    margin: theme.spacing(2),
  },
}));
