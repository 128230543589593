import { makeStyles, lighten, darken } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: "10px"
  },
  closeBtn: {
    float: "right",
    marginRight: 10
  },
  flexInput: {
    margin: theme.spacing(1),
    flexGrow: "1",
  },
  validationTypeID: {
    flexBasis: "50%",
    maxWidth: "180px",
  },
  createBtn: {
    paddingTop: "20px",
    marginTop: theme.spacing(2),
  },
  rateSwitch: {
    width: "100%",
  },
  table: {
    tableLayout: "auto",
  },
  inlineRoot: {
    display: "inline-flex",
    width: "100%",
    "& .validationTypeID": {
      minWidth: "150px"
    },
    "& .deleteBtn": {
      margin: theme.spacing(2),
      maxHeight: "35px",
    },
    "& .email-btn": {
      margin: theme.spacing(2),
      maxHeight: "35px",
    },
    "& .downloadHistoryBtn": {
      margin: theme.spacing(2),
      maxHeight: "35px",
      minHeight: "35px",
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  selectFormControl: { 
    marginTop: "8px",
    width: "100%" 
  },
  selectLabel: {
    marginLeft: "8px",
  },
  acceptedAtError: {
    marginLeft: "8px",
    marginTop: "-4px"
  },
  scopeButton: {
    margin:4,
    fontSize:"smaller"
  },
  selectedListItem: {
    '&.scope.selected':{
      backgroundColor:lighten(theme.palette.amano.main,.6),
      color:darken(theme.palette.amano.main,.4),
    }
  },
}));
