import React from "react";
import PropTypes from "prop-types";
import {
  pdf,
  Document,
  Page,
  Image,
  Text,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import QRCode from "qrcode";
import moment from "moment";

// NOTE: this current document is formatted for an Avery type sticker printer
// Once we are supporting more template types, we'll want to move this to its own file, and grab it based on type
const ValidationDocument = ({ merchantName, offerName, validations }) => {
  const styles = StyleSheet.create({
    rootContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      marginTop: 22,
      marginBottom: 15,
      marginLeft: 15
    },
    leftContentContainer: {
      display: "flex",
      flexDirection: "row",
      minWidth: "2.6in",
      maxWidth: "2.6in",
      marginTop: 10,
      marginBottom: 10,
      paddingLeft: -40
    },
    middleContentContainer: {
      display: "flex",
      flexDirection: "row",
      minWidth: "2.6in",
      maxWidth: "2.6in",
      marginTop: 10,
      marginBottom: 10,
      paddingLeft: 20
    },
    rightContentContainer: {
      display: "flex",
      flexDirection: "row",
      minWidth: "2.6in",
      maxWidth: "2.6in",
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 10,
      paddingLeft: 30
    },
    qrContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    qrCode: {
      height: "50px",
      width: "50px",
    },
    header: {
      marginBottom: 5,
    },
    defaultFont: {
      fontSize: "10px",
    },
    uses: {
      marginBottom: 5,
    },
    dateRange: {},
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    leftContent: {},
  });

  let rowCounter = -1;

  const getContainerStyle = (counter) => {
    if(counter == 0) {
      return styles.leftContentContainer
    } else if (counter == 1) {
      return styles.middleContentContainer
    } else if (counter == 2) {
      return styles.rightContentContainer
    }
  };

  return (
    <Document>
      {validations?.flatMap((row, index) => {
        return (
          <Page key={index}>
              <View style={styles.rootContainer}>
                  {row.map((validation, innerIndex) => {
                    rowCounter += 1;
                    if(rowCounter > 2) rowCounter = 0;
                      return (
                        <View key={innerIndex} style={getContainerStyle(rowCounter)} wrap={false}>
                          <View className={styles.leftContent}>
                            <View style={[styles.header, styles.defaultFont]}>
                              <Text>{merchantName}</Text>
                            </View>
                            <View style={[styles.header, styles.defaultFont]}>
                              <Text>{offerName}</Text>
                            </View>
                            <View style={[styles.uses, styles.defaultFont]}>
                              <Text>{`Uses: ${validation.maxUses}`}</Text>
                            </View>
                            <View style={[styles.dateRange, styles.defaultFont]}>
                              <Text>
                                {`${moment
                                  .utc(validation.activationDate)
                                  .local()
                                  .format("MM/DD/YYYY")} - ${moment
                                  .utc(validation.expirationDate)
                                  .local()
                                  .format("MM/DD/YYYY")}`}
                              </Text>
                            </View>
                          </View>
                          <View style={styles.qrContainer}>
                            <Image src={validation.barcodeValue} style={styles.qrCode} />
                          </View>
                        </View>
                      )            
                  })}
                  </View>
                </Page>
        )  
      })}
    </Document>
  );
};

ValidationDocument.propTypes = {
  merchantName: PropTypes.string.isRequired,
  offerName: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(
    PropTypes.shape({
      barcodeValue: PropTypes.string.isRequired,
      maxUses: PropTypes.number.isRequired,
      activationDate: PropTypes.string.isRequired,
      expirationDate: PropTypes.string.isRequired,
    })
  ),
};

export default function useValidationPDFGenerator() {
  const generate = async (merchantName, offerName, content) => {
    const mutated = await Promise.all(
      content.map(async (validation) => {
        const result = await QRCode.toDataURL(validation.barcodeValue, {
          type: "png",
        });
        validation.barcodeValue = result;
        return validation;
      })
    );

    const formatArray = (mutated) => {
      if(mutated?.length <= 0) return;
      
      let result = [];
      
      while(mutated.length) {
          result.push(mutated.splice(0, 30));
      }

      return result;
    }

    const doc = (
      <ValidationDocument
        merchantName={merchantName}
        offerName={offerName}
        validations={formatArray(mutated)}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `validations-${+new Date()}.pdf`;
    link.click();
  };

  return {
    generate,
  };
}
