export const isDiscountType = (type) => {
  switch (type?.toLowerCase()) {
    case "amount":
    case "percentage":
    case "adjust entry time":
    case "adjust exit time":
      return true;
    default:
      return false;
  }
};
