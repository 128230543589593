import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  input: {
    margin: theme.spacing(1),
  },
  btnContainer: {
    marginLeft: "0px",
    marginTop: "5px",
  },
  flexInput: { margin: theme.spacing(1), flexGrow: "1" },
  inlineRoot: {
    display: "inline-flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    "& .deleteBtn": {
      margin: theme.spacing(2),
      marginLeft: 0,
      maxHeight: "35px",
    },
    "& .saveBtn": {
      margin: theme.spacing(2),    
      maxHeight: "35px",
    },
    "& .cancelBtn": {
      margin: theme.spacing(2),
      marginLeft: 0,
      maxHeight: "35px",
    },
    "& .vechiletype-form": {
      margin: theme.spacing(2),
      margin: "9px",
      minWidth: "90px"
    },
    "& .vehicle-license-plate": {
      minWidth: "100px"
    },
  },
  checkboxLabel: {
    transform: "translate(0, 1.5px) scale(0.75)",
    padding: "0",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1",
    clear: "both",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      transform: "unset",
    },
  },
}));
