import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  contentContainer: { display: "block" },
  flexInput: {
    margin: theme.spacing(1),
    width: "100%",
  },
  downloadBtnContainer: {
    display: "flex",
    float: "right",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  downloadProgress: {
    marginRight: theme.spacing(2),
  },
  cardHeader: {
    color: theme.palette.primary.main,
  },
  downloadBtn: {},
  formError: {
    color: theme.palette.error?.dark,
    fontSize: "small",
    marginLeft: theme.spacing(3),
  },
}));
