import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { useFormik } from "formik";
import { useStyles } from "./style";
import clsx from "clsx";
import { TextField } from "@material-ui/core";
import Title from "../../Title";
import * as Yup from "yup";
import UserService from "../../../services/UserService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { useSelector, shallowEqual } from "react-redux";
import { USER_TYPES } from "../../../constants";
import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import useHasPermissions from "../../../hooks/useHasPermissions";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import ValidationAccountService from "../../../services/ValidationAccountService";
import CreateOnePass from "../../OnePass/CreateOnePass";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";
import AccessGroupService from "../../../services/AccessGroupService";

const userService = new UserService(apiClient);
const validationAccountService = new ValidationAccountService(apiClient);
const accessGroupService = new AccessGroupService(apiClient);

export default function ValidationAccountUserForm({
  onCancel,
  userID,
  onSubmitComplete,
  handleResend,
  isResetting,
  validationAccountId
}) {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const facilityID = useSelector(
    (state) => state.entities?.ContextID,
    shallowEqual
  );
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const [user, setUser] = useState({});
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [userPermissions, setUserPermissions] = useState({
    createprintedvalidations: false,
    validatetickets: false,
    validationaccountsettings: false,
    validationdownloadhistory: false,
    createonepass: false,
    showroomfield: false,
    shownamefield: false,
  });
  const [automaticallyapplyvalidation, setAutomaticallyapplyvalidation] = useState(false);
  const [validationOffers, setValidationOffers] = React.useState([]);
  const [groups, setGroups] = useState([]);

  const isOnePassFeatureEnabled = useFeatureFlag("ONE Pass");
  const { hasPermissions } = useHasPermissions();
  const createPrintedValPerm = hasPermissions(["createprintedvalidations"]);
  const validateTicketsPerm = hasPermissions(["validatetickets"]);
  const validationAccountSettingPerm = hasPermissions([
    "validationaccountsettings",
  ]);
  const validationDownloadHistoryPerm = hasPermissions([
    "validationdownloadhistory",
  ]);
  const validationAccountUsersAdd = hasPermissions([
    "validationaccountusers.add",
  ]);


  useEffect(() => {
    if (!_.isUndefined(userID))
      getUser(userID).then((userInfo) => {
        let autoOfferId = GetAutoValidationOfferIdForAccount(userInfo);
        userInfo.selectedAutomaticValidationOfferForValidate = autoOfferId >= 1 ? autoOfferId.toString() : "";
        setUser(userInfo);
        setAutomaticallyapplyvalidation(autoOfferId >= 1);
        setSelectedValidationOffer(autoOfferId);
        validateForm();
        const groupPermissions = GetGroupPermissionsFromData(userInfo);
        if (groupPermissions) {
          setUserPermissions({
            createprintedvalidations:
              groupPermissions.indexOf("createprintedvalidations") > -1,
            validatetickets: groupPermissions.indexOf("validatetickets") > -1,
            validationaccountsettings:
              groupPermissions.indexOf("validationaccountsettings") > -1,
            validationdownloadhistory:
              groupPermissions.indexOf("validationdownloadhistory") > -1,
            createonepass: groupPermissions.indexOf("createonepass") > -1,
            showroomfield: groupPermissions.indexOf("showroomfield") > -1,
            shownamefield: groupPermissions.indexOf("shownamefield") > -1
          });
        }
      });
  }, [userID]);

  const GetAutoValidationOfferIdForAccount = (userInfo) => {
    for (let i = 0; i < userInfo?.autovalidationOffers?.length; i++) {
      if (validationAccountId === userInfo?.autovalidationOffers?.[i]?.validationAccountID) {
        return userInfo?.autovalidationOffers?.[i]?.validationOfferID;
      }
    }
    return 0;
  }

  const GetGroupPermissionsFromData = (userInfo) => {
    if (scopeAwareFacilityID === null || scopeAwareFacilityID === undefined) return null;
    if (userInfo === null || userInfo === undefined) return null;

    for (let i = 0; i < userInfo?.entities?.length; i++) {
      if (scopeAwareFacilityID === userInfo?.entities?.[i]?.scope) {
        return userInfo?.entities?.[i]?.groups?.[0]?.permissions;
      }
    }
    return null;
  }

  const ValidationUserSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required"),
    validateTickets: Yup.string().optional(),
    selectedAutomaticValidationOffer: Yup.string().optional(),
    selectedAutomaticValidationOfferForValidate: Yup.string().optional()
    .oneOf([null, ...validationOffers?.filter(offer => offer.active).map((offer) => {
      return String(offer.id);
    })],"Validation Offer must be active"),
    accessGroupId: Yup.string().nullable().optional(),
    onePassValidUntil: Yup.date().nullable().optional(),
  });

  useEffect(() => {
    getValidationOffers();
    isOnePassFeatureEnabled && fetchAccessGroups();
  }, [validationAccountId, facilityID, isOnePassFeatureEnabled]);

  const getValidationOffers = useCallback(async () => {
    if (_.isUndefined(validationAccountId) || _.isUndefined(facilityID)) {
      setValidationOffers();
      return;
    }

    let response;
    try {
      response = await validationAccountService.getMerchantValidationOffers(
        validationAccountId,
        scopeAwareFacilityID
      );

      setValidationOffers(response.data);
      if (!_.isUndefined(userID)) validateForm();

    } catch {
      enqueueSnackbar("Failed to retrieve validation offers", {
        variant: "error", tag: "FailedToRetrieveValidationOffers"
      });
      return;
    }
  }, [validationAccountId, facilityID]);

  const fetchAccessGroups = useCallback(async () => {
    let response;
    try {
      response = await accessGroupService.GetAccessGroups(
        scopeAwareFacilityID,
        100,
        0,
        ""
      );
    } catch {
      enqueueSnackbar("Failed to retrieve access groups", {
        variant: "error",
        tag: "fetchAccessGroupsError"
      });
      return;
    }

    let tmpGroups =
      response?.data?.collection?.map(group => {
        return { name: group.name, value: group.accessGroupID };
      }) ?? [];
    setGroups(tmpGroups);
  }, [facilityID]);

  const { dirty, errors, handleChange, submitForm, values, setFieldValue, validateForm } = useFormik({
    initialValues: { ...user, selectedAutomaticValidationOffer: "", accessGroupId: user?.accessGroupId, onePassValidUntil: user?.onePassValidUntil } || {},
    enableReinitialize: true,
    validationSchema: ValidationUserSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  const getUser = useCallback(
    async (userID) => {
      try {
        const response = await userService.getUserForValidationAccount(userID);
        return response.data;
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Failed to retrieve user details", {
          variant: "error",
          tag: "FailedToRetrieveUserDetails",
        });
      }
    },
    [userID]
  );

  async function handleSubmit(values) {
    const { userId } = values;

    if (_.isUndefined(userId)) {
      await createValidationAccountUser(values);
    } else {
      await updateValidationAccountUser(values);
    }
  }

  const createValidationAccountUser = async (values) => {
    try {
      const { firstName, lastName, email, selectedAutomaticValidationOffer, accessGroupId, onePassValidUntil } = values;

      let autoOfferId = null;
      if (automaticallyapplyvalidation) {
        if (selectedAutomaticValidationOffer === "") {
          let tmp = GetAutoValidationOfferIdForAccount(values);
          if (tmp > 0)
            autoOfferId = tmp;
        } else {
          autoOfferId = selectedAutomaticValidationOffer;
        }
      }

      const userResponse = await userService.createUserForValidationAccount({
        firstName,
        lastName,
        email,
        entities: [
          {
            scope: scopeAwareFacilityID,
            permissions: _.keys(
              _.pickBy({
                ...userPermissions,
                "validationaccounts.view":
                  userPermissions.validationaccountsettings,
                "validationaccountusers.add":
                  userPermissions.validationaccountsettings,
                "validationaccountusers.edit":
                  userPermissions.validationaccountsettings,
                "validationaccountusers.delete":
                  userPermissions.validationaccountsettings,
                "validationaccounts.edit":
                  userPermissions.validationaccountsettings,
                ValidationStatement: true, // V/A users get this by default as of https://amanomcgann.atlassian.net/browse/AO-5516
              })
            ),
          },
        ],
        userType: USER_TYPES.ValidationAccount,
        autoValidationOfferId: autoOfferId,
        validationAccountId: validationAccountId,
        accessGroupId: accessGroupId || null,
        onePassValidUntil: onePassValidUntil || null
      });
      enqueueSnackbar("Successfully created validation account user", {
        variant: "success",
      });

      onSubmitComplete(userResponse.data);
    } catch (err) {
      let errorMessage = "Failed to create validation account user";
      if (err?.response?.data?.message != undefined && err?.response?.data?.message != null) {
        errorMessage = err?.response?.data?.message;
      }

      enqueueSnackbar(errorMessage, {
        variant: "error",
        tag: "FailedToCreateValidationAccountUser",
      });
    }
  };

  const updateValidationAccountUser = async (values) => {
    try {      
      const { selectedAutomaticValidationOffer, accessGroupId, onePassValidUntil } = values;
      let autoOfferId = null;
      if (automaticallyapplyvalidation) {
        if (selectedAutomaticValidationOffer === "") {
          let tmp = GetAutoValidationOfferIdForAccount(values);
          if (tmp > 0)
            autoOfferId = tmp;
        } else {
          autoOfferId = selectedAutomaticValidationOffer;
        }
      }

      const response = await userService.updateUserForValidationAccount({
        ...values,
        entities: [
          {
            scope: scopeAwareFacilityID,
            permissions: _.keys(
              _.pickBy({
                ...userPermissions,
                "validationaccounts.view":
                  userPermissions.validationaccountsettings,
                "validationaccountusers.add":
                  userPermissions.validationaccountsettings,
                "validationaccountusers.edit":
                  userPermissions.validationaccountsettings,
                "validationaccountusers.delete":
                  userPermissions.validationaccountsettings,
                "validationaccounts.edit":
                  userPermissions.validationaccountsettings,
                ValidationStatement: true, // V/A users get this by default as of https://amanomcgann.atlassian.net/browse/AO-5516
              })
            ),
          },
        ],
        autoValidationOfferId: autoOfferId,
        validationAccountId: validationAccountId,
        accessGroupId: accessGroupId || null,
        onePassValidUntil: onePassValidUntil || null
      });

      enqueueSnackbar("Successfully updated validation account user", {
        variant: "success",
      });
      onSubmitComplete(response.data);
    } catch (err) {
      enqueueSnackbar("Failed to update validation account user", {
        variant: "error",
        tag: "FailedToUpdateValidationAccountUser",
      });
    }
  };

  const handleUserPermissionSelect = (event) => {
    handleChange(event);
    setUserPermissions({
      ...userPermissions,
      [event.target.name]: event.target.checked,
    });
  };

  const shouldOptionDisplay = (permissionOption) => {
    if (validationAccountSettingPerm) {
      switch (permissionOption) {
        case "createPrintedValidations":
          return createPrintedValPerm;
        case "validateTickets":
          return validateTicketsPerm;
        case "validationAccountSettings":
          return validationAccountSettingPerm;
        case "validationDownloadHistory":
          return validationDownloadHistoryPerm;
      }
    } else {
      return true;
    }
  };

  const handleValidateTicketsCheckboxChange = async (event) => {
    handleUserPermissionSelect(event);
    if (!event.target.checked) {
      setUserPermissions((prevPermissions) => ({
        ...prevPermissions,
        validatetickets: false,
      }));
      setAutomaticallyapplyvalidation(false);
      setSelectedValidationOffer(0);
      setFieldValue("selectedAutomaticValidationOfferForValidate", "");
    }
  }

  const handleAutomaticallyApplyValidationCheckboxChange = (event) => {
    handleChange(event);
    setAutomaticallyapplyvalidation(event.target.checked);
    if (!event.target.checked) {
      setSelectedValidationOffer(0);
      setFieldValue("selectedAutomaticValidationOfferForValidate", "");
    }
  }

  const handleResendClick = () => {
    handleResend(user);
  };

  const {
    createprintedvalidations,
    validatetickets,
    validationaccountsettings,
    validationdownloadhistory,
    createonepass,
    shownamefield,
    showroomfield
  } = userPermissions;

  const [selectedValidationOffer, setSelectedValidationOffer] = useState("");

  const handleDropdownChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedValidationOffer(selectedValue);

    setFieldValue("selectedAutomaticValidationOffer", String(selectedValue));
    setFieldValue("selectedAutomaticValidationOfferForValidate", String(selectedValue));
  };

  return (
    <div className={classes.root}>
      <Title>Create Validation Account User</Title>
      <div>
        <TextField
          className={clsx("first-name", classes.flexInput)}
          id="firstName"
          name="firstName"
          variant="outlined"
          label="First Name"
          value={values["firstName"] ?? ""}
          onChange={handleChange}
          error={errors && errors.firstName ? true : false}
          helperText={errors && errors.firstName}
        />
        <TextField
          className={clsx("last-name", classes.flexInput)}
          id="lastName"
          name="lastName"
          variant="outlined"
          label="Last Name"
          value={values["lastName"] ?? ""}
          onChange={handleChange}
          error={errors && errors.lastName ? true : false}
          helperText={errors && errors.lastName}
        />
        <TextField
          className={clsx("email", classes.flexInput)}
          id="email"
          name="email"
          variant="outlined"
          label="Email Address"
          value={values["email"] ?? ""}
          onChange={handleChange}
          error={errors && errors.email ? true : false}
          helperText={errors && errors.email}
          disabled={!_.isUndefined(user?.userId)}
          InputProps={{
            readOnly: Boolean(!_.isUndefined(user?.userId)),
            "aria-readonly": Boolean(!_.isUndefined(user?.userId)),
            disabled: Boolean(!_.isUndefined(user?.userId)),
            "aria-label": "Email Address",
          }}
        />
        <FormGroup className={clsx("permission-options", classes.flexInput)}>
          {shouldOptionDisplay("createPrintedValidations") && (
            <>
              {/* Create Printed Validations */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createprintedvalidations}
                    onChange={handleUserPermissionSelect}
                    name="createprintedvalidations"
                    value={values["createPrintedValidations"]}
                  />
                }
                label="Create Printed Validations"
                className={clsx("create-printed-val-checkbox")}
              />
              <Divider />
            </>
          )}
          {shouldOptionDisplay("validateTickets") && (
            <>
              {/* Validate Tickets Online */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={validatetickets}
                    onChange={handleValidateTicketsCheckboxChange}
                    name="validatetickets"
                    value={values["validateTickets"]}
                    data-testid="validate-tickets"
                  />
                }
                label="Validate Tickets Online"
                className={clsx("validate-tickets-checkbox")}
              />
              <Divider />
              {
                <div className={classes.subCheckbox}>
                  {/* Auto Apply Validation */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          automaticallyapplyvalidation && validatetickets
                        }
                        onChange={
                          handleAutomaticallyApplyValidationCheckboxChange
                        }
                        name="automaticallyapplyvalidation"
                        value={automaticallyapplyvalidation}
                        disabled={!validatetickets}
                        data-testid="automatically-apply-validation"
                      />
                    }
                    label="Auto Apply Validation"
                    className={clsx("auto-apply-validation-checkbox")}
                  />
                  <Divider />

                  <div style={{ display: "flex" }}>
                    <FormControl
                      className={classes.dropdown}
                      error={errors && errors.selectedAutomaticValidationOfferForValidate ? true : false}>
                      <Select
                        label="Select Validation"
                        onChange={handleDropdownChange}
                        placeholder="Select..."
                        value={selectedValidationOffer}
                        disabled={
                          !automaticallyapplyvalidation || !validatetickets
                        }
                        name="selectedAutomaticValidationOffer"
                        data-testid="select-validation-offer"
                      >
                        <MenuItem disabled value="select">
                          Select...
                        </MenuItem>
                        {validationOffers.map((offer, index) => (
                          <MenuItem
                            data-id={offer.name}
                            key={index}
                            value={offer.id}
                            disabled={!offer.active}
                          >
                            {offer.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors.selectedAutomaticValidationOfferForValidate}>{errors.selectedAutomaticValidationOfferForValidate}</FormHelperText>
                    </FormControl>
                    <Divider/>
                  </div>
                </div>
              }
            </>
          )}

          {isOnePassFeatureEnabled && (
            <CreateOnePass
              values={values}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              options={groups}
              createOnePassPerm={createonepass}
              onPermissionChange={handleUserPermissionSelect}
              showNameFieldPerm={shownamefield}
              showRoomFieldPerm={showroomfield}
              formErrors={errors}
              updateUserPermissions={setUserPermissions}
            />
          )}

          {shouldOptionDisplay("validationAccountSettings") && (
            <>
              {/* Validation Account Settings */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={validationaccountsettings}
                    onChange={handleUserPermissionSelect}
                    name="validationaccountsettings"
                    value={values["validationAccountSettings"]}
                  />
                }
                label="Validation Account Settings"
                className={clsx("validation-account-settings-checkbox")}
              />
              <Divider />
            </>
          )}

          {shouldOptionDisplay("validationDownloadHistory") && (
            <>
              {/* Validation Download History */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={validationdownloadhistory}
                    onChange={handleUserPermissionSelect}
                    name="validationdownloadhistory"
                    value={values["validationDownloadHistory"]}
                  />
                }
                label="Validation Download History"
                className={clsx("validation-download-history-checkbox")}
              />
            </>
          )}
        </FormGroup>
      </div>
      <div className={classes.bottomBtnGroup}>
        <Button
          className={clsx("save-button")}
          color="primary"
          variant="contained"
          disabled={
            !dirty || (automaticallyapplyvalidation && !selectedValidationOffer)
          }
          onClick={submitForm}
          startIcon={small ? <SaveIcon style={{ marginLeft: "33%" }} /> : <></>}
          data-testid="save-button"
        >
          {!small && "Save"}
        </Button>
        <Button
          className={clsx("cancel-button")}
          color="secondary"
          variant="contained"
          onClick={onCancel}
          startIcon={
            small ? <CancelIcon style={{ marginLeft: "33%" }} /> : <></>
          }
        >
          {!small && "Cancel"}
        </Button>
        {!_.isUndefined(user?.userId) && validationAccountUsersAdd && (
          <Button
            className={clsx("btn-resend")}
            startIcon={
              isResetting ? (
                <CircularProgress size={24} color="secondary" />
              ) : (
                <RotateLeftIcon />
              )
            }
            variant="contained"
            disabled={isResetting}
            onClick={handleResendClick}
          >
            {!small && "Resend Invitation"}
          </Button>
        )}
      </div>
    </div>
  );
}

ValidationAccountUserForm.defaultProps = {
  onCancel: () => { },
  onSubmitComplete: () => { },
};

ValidationAccountUserForm.propTypes = {
  onCancel: PropTypes.func,
  onSubmitComplete: PropTypes.func,
  userID: PropTypes.string,
};
