import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./style";
import ValidationAccountPanel from "../../Panels/Validations/ValidationAccount";
import ValidationOffersPanel from "../../Panels/Validations/ValidationOffers";
import ValidationAccountService from "../../../services/ValidationAccountService";
import apiClient from "../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { isUndefined, isNaN } from "lodash";
import clsx from "clsx";
import ValidationUsersPanel from "../../Panels/ValidationUsers";

const validationAccountService = new ValidationAccountService(apiClient);

const ValidationAccountForm = ({
  contractHolderID,
  onChange,
  onDelete,
  facilityID,
}) => {
  const classes = useStyles();
  const [accountIsFetched, setAccountIsFetched] = useState(false);
  const [validationAccount, setValidationAccount] = useState({});
  const enqueueSnackbar = useEnqueueSnackbar();

  const handleValidationAccountChange = (validationAccount) => {
    setValidationAccount(validationAccount);
    onChange(validationAccount);
  };

  const handleValidationAccountDelete = () => {
    setValidationAccount({ contractHolderID: undefined });
    onDelete();
  };

  const fetchValidationAccount = async (facilityID, contractHolderID) => {
    let response;
    try {
      response = await validationAccountService.getValidationAccount(
        facilityID,
        contractHolderID
      );
    } catch {
      enqueueSnackbar(
        `Failed to retrieve Validation Account for ${facilityID}, ${contractHolderID}`,
        {
          variant: "error",
          tag: "FailedToRetrieveValidationAccount",
        }
      );
      return;
    }
    onChange(response.data);
    setValidationAccount({ ...validationAccount, ...response.data });
    setAccountIsFetched(true);
  };

  useEffect(() => {
    if (
      (!isUndefined(validationAccount.contractHolderID) ||
        Object.keys(validationAccount).length === 0) &&
      !isUndefined(contractHolderID) &&
      !isNaN(contractHolderID)
    ) {
      fetchValidationAccount(facilityID, contractHolderID);
    } else {
      setAccountIsFetched(true);
    }
  }, [facilityID, validationAccount.contractID]);

  return (
    <div className={classes.root}>
      {accountIsFetched && (
        <ValidationAccountPanel
          data-testid={"validation-account-panel"}
          className={clsx(["validation-account-panel", classes.flexChild])}
          validationAccountData={validationAccount}
          contractHolderID={contractHolderID}
          onChange={handleValidationAccountChange}
          onDelete={handleValidationAccountDelete}
        />
      )}
      {accountIsFetched && (
        <ValidationOffersPanel
          data-testid={"validation-offers-panel"}
          className={clsx(["validation-offers-panel", classes.flexChild])}
          entityID={facilityID}
          validationAccountData={validationAccount}
          contractHolderID={contractHolderID}
        />
      )}
      {accountIsFetched && (
        <ValidationUsersPanel
          className={clsx("validation-user-panel", classes.flexChild)}
          validationAccountID={contractHolderID}
        />
      )}
    </div>
  );
};

ValidationAccountForm.defaultProps = {
  onChange: () => {},
  onDelete: () => {},
};

ValidationAccountForm.propTypes = {
  validationAccountName: PropTypes.string,
  contractHolderID: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default ValidationAccountForm;
