import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  flexInput: { 
    margin: theme.spacing(1), 
    flexGrow: "1",
    display: "inline-flex",
  },
  dropdown: { 
    flexGrow: "1",
  },
  bottomBtnGroup: {
    "& button": {
      margin: theme.spacing(1),
    },
  },
  subCheckbox: {
    marginLeft: 16
  },
}));
