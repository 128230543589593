import React, {useState} from "react"
import clsx from "clsx";
import { useStyles } from "./style";
import moment from "moment";
import EnhancedTableHead from "../../EnchancedTableHead/EnhancedTableHead";
import {
    Grid, 
    Table, 
    TableRow, 
    TableBody, 
    TableCell, 
  } from "@material-ui/core";

const DownloadHistoryTable = ({
    historyData
}) => {  
    const classes = useStyles();
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("creationDate"); 
 
    const getComparator = (order, orderBy) => {
        return order === "desc"
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      };
      
      const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      };
    
      const stableSort = (array, comparator) => {
        let unassignedList = [];
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
      
        if (unassignedList != undefined && unassignedList.length > 0) {
          unassignedList.forEach(row => {
            row.assignedAt = null;
          });
        }
      
        return stabilizedThis.map(el => el[0]);
      };
      
      const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
      };   
        
    const headerRow = [
        { id: "validationCount", numeric: false, disablePadding: false, label: "Quantity" },
        { id: "validationOfferName", numeric: false, disablePadding: false, label: "Validation Name" },
        { id: "maxUses", numeric: false, disablePadding: false, label: "Max Uses" },
        { id: "maxUsesPerTrx", true: false, disablePadding: false, label: "Uses Per Transaction" },
        { id: "activationDate", numeric: false, disablePadding: false, label: "Valid From/Until" },
        { id: "email", numeric: false, disablePadding: false, label: "Username" },
        { id: "creationDate", numeric: false, disablePadding: false, label: "Date and Time" },
      ];    

    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <Table className={classes.table}>
                <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headerRow={headerRow}
                />                              
                    <TableBody className={clsx("history-table-body")}>
                        {(stableSort(historyData, getComparator(order, orderBy))
                        ).map((row, index)=>{
                            return(
                            <TableRow key={index}>
                                <TableCell>
                                    {row.validationCount}
                                </TableCell>    
                                <TableCell>
                                    {row.validationOfferName}
                                </TableCell>    
                                <TableCell>
                                    {row.maxUses}
                                </TableCell>    
                                <TableCell>
                                    {row.maxUsesPerTrx}
                                </TableCell>    
                                <TableCell>
                                {(
                                    <div >
                                        {moment
                                        .utc(row.activationDate)
                                        .format("MM/DD/YYYY - ")
                                        .toString()}
                                        {moment
                                        .utc(row.expirationDate)
                                        .format("MM/DD/YYYY")
                                        .toString()}
                                    </div>
                                    )}
                                </TableCell>    
                                <TableCell>
                                    {row.email}
                                </TableCell>    
                                <TableCell>
                                    {(
                                    <div >
                                        {moment
                                        .utc(row.creationDate)
                                        .format("MM/DD/YYYY hh:mm A")
                                        .toString()}
                                    </div>
                                    )}
                                </TableCell>    
                            </TableRow>
                            )
                        })
                        }                   
                    </TableBody>
                </Table>
            </Grid>          
        </Grid>
    );
};
 
export default DownloadHistoryTable;